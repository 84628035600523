.mazeFloor {
  background-image: url("mazeFloor2.d0dc327e.png");
  grid-template-rows: repeat(3, 130px);
  grid-template-columns: repeat(3, 130px);
  margin: 30px;
  display: grid;
}

.mazeSquare {
  z-index: 3;
  width: 50px;
  height: 50px;
}

.activeSquare {
  background-image: url("mazeWhale.2c7e09dc.gif");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 90%;
}

.targetSquare {
  background-image: url("mazeWater.9db2e3c6.gif");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.puzzleMazeBeetle {
  position: absolute;
}

.mazeDeco {
  z-index: 1;
  width: 20px;
  height: 20px;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.mazeDeco1 {
  background-image: url("mazeDeco1.b4577b02.png");
}

.mazeDeco2 {
  background-image: url("mazeDeco2.1c9a2c13.png");
}

.mazeDeco3 {
  background-image: url("mazeDeco3.4313c3d9.png");
}

/*# sourceMappingURL=index.96739756.css.map */
