/* Puzzle Maze */

.mazeFloor {
    margin: 30px;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    grid-template-rows: repeat(3, 130px);
    background-image: url(../../img/puzzles/mazeFloor2.png);
}

.mazeSquare {
    z-index: 3;
    width: 50px;
    height: 50px;
    /* background-color: rgb(78, 58, 91); */
    /* background-image: url(../../img/puzzles/mazeFloor.png);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover; */
}

.activeSquare {
    /* background-color: rgb(145, 68, 213); */
    /* background-color: rgb(115, 84, 136); */
    background-image: url(../../img/puzzles/mazeWhale.gif);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 90%;
    /* background-size:initial; */
}

.targetSquare {
    /* background-color: rgb(146, 144, 146); */
    background-image: url(../../img/puzzles/mazeWater.gif);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size:100%;
}

.forbiddenSquare {
    /* background-color: orange; */
}

.puzzleMazeBeetle {
    position:absolute;
}

.mazeDeco {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
}

.mazeDeco1 {
    background-image: url(../../img/puzzles/mazeDeco1.png);
}

.mazeDeco2 {
    background-image: url(../../img/puzzles/mazeDeco2.png);
}

.mazeDeco3 {
    background-image: url(../../img/puzzles/mazeDeco3.png);
}